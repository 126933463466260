<template>
  <el-row>
    <el-col>
      <el-card>
        <el-table
        :data="tableData"
        style="width: 100%">
          <el-table-column
            prop="name"
            label="套餐名称"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="num"
            label="链接数量"
            width="140"
            align="center"
          >
            <template #default="scope">
              {{ scope.row.link_num }}/{{ scope.row.num }}条
            </template>
          </el-table-column>
          <el-table-column
            prop="adver"
            label="是否有广告"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="api"
            label="API功能"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createtime"
            label="开始时间"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="expiretime"
            label="到期时间"
            width="140"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-card>
      <el-card class="page__footer">
        <el-pagination
          background
          :small="responsively"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total"
        />
      </el-card>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  item_id: number,
  name: number,
  intro: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  num: string,
  day: string,
  createtime: string,
  payment: string,
  price: number
}

interface IState {
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData[] | null,
}

export default defineComponent({
  name: 'HistoryPackage',
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/fee/useritem', {
        page: state.page,
        page_size: state.pageSize
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      ...stateAsRefs,
      responsively,
      getTableList,
      handleSizeChange,
      handleCurrentChange
    }
  }
})
</script>

<style lang="scss" scoped>
  .page {
    @mixin cardBody {
      :deep(.el-card__body) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
      }
    }
    &__footer {
      margin: 12px 0 0;
      @include cardBody;
    }
  }
</style>
