
import { IResponse } from '@/common/http'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
interface ITableData {
  id: number,
  // eslint-disable-next-line camelcase
  item_id: number,
  name: number,
  intro: string,
  // eslint-disable-next-line camelcase
  out_trade_no: string,
  num: string,
  day: string,
  createtime: string,
  payment: string,
  price: number
}

interface IState {
  page: number,
  pageSize: number,
  total: number,
  tableData: ITableData[] | null,
}

export default defineComponent({
  name: 'HistoryPackage',
  setup () {
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: null
    })
    const stateAsRefs = toRefs<IState>(state)
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)

    const getTableList = (): void => {
      proxy.$axios.get('/fee/useritem', {
        page: state.page,
        page_size: state.pageSize
      }).then((res: IResponse) => {
        state.total = res.data.count
        state.tableData = res.data.lists
      })
    }
    getTableList()

    const handleSizeChange = (val: number): void => {
      state.pageSize = val
      getTableList()
    }
    const handleCurrentChange = (val: number): void => {
      state.page = val
      getTableList()
    }

    return {
      ...stateAsRefs,
      responsively,
      getTableList,
      handleSizeChange,
      handleCurrentChange
    }
  }
})
